import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "@/store/useAuthStore.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMe } from "@/hooks/useMe.tsx";
import useMenuStore from "@/store/useMenuStore";
import { Bolt, ChartNoAxesCombined, DollarSign } from "lucide-react";
import { Badge } from "@/components/ui/badge.tsx";
import NavButton from "@/components/NavButton.tsx";
import PricingModal from "@/components/PricingModal.tsx";
import { OnboardingWrapper } from "@/components/onboarding/OnboardingWrapper.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";

export const UserLayout = () => {
  const { accessToken } = useAuthStore();
  const isAuthenticated = accessToken !== "";
  const { clearToken } = useAuthStore();
  const navigation = useNavigate();
  const { menuShown } = useMenuStore();

  const signOut = () => {
    clearToken();
    navigation("/");
  };

  const { me } = useMe({});
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex w-full h-full">
      {me?.subscription === false && <PricingModal />}

      <div
        className={`flex ${
          !menuShown && "hidden"
        } bg-white max-w-[220px] border-r-[1px] border-r-gray-300 min-w-[220px] flex-col p-[16px] justify-between h-screen`}
      >
        <div className="flex flex-col gap-6 text-black">
          <div className="flex flex-row justify-between">
            {me?.email === undefined ? (
              <Skeleton className={"w-28 h-6"} />
            ) : (
              <div>
                Hi,{" "}
                <span className="font-medium">
                  {me?.email && me.email.split("@")[0]}{" "}
                </span>
              </div>
            )}

            {me?.subscription === undefined ? (
              <Skeleton className={"w-12 h-6"} />
            ) : (
              <Badge
                className={`w-fit px-1.5 rounded-full items-center pointer-events-none shadow-none justify-center ${
                  me?.subscription
                    ? "text-green bg-green-soft"
                    : "bg-red-soft text-red"
                }`}
              >
                {me?.subscription ? "Active" : "Inactive"}
              </Badge>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <NavButton to="/trends" icon={ChartNoAxesCombined}>
              Trends
            </NavButton>
            <NavButton to="/billing" icon={DollarSign}>
              Billing
            </NavButton>
            <NavButton to="/settings" icon={Bolt}>
              Settings
            </NavButton>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <p className="text-sm text-center text-gray-500">{me?.email}</p>
          <Button variant="exit" size="sm" onClick={signOut}>
            Sign out
          </Button>
        </div>
      </div>
      <OnboardingWrapper>
        <div className="flex w-full overflow-hidden">
          <Outlet />
        </div>
      </OnboardingWrapper>
    </div>
  );
};
