import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import AxiosInstance from "@/services/axios-instance.ts";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import useAuthStore from "@/store/useAuthStore.tsx";

export const useChangeEmail = () => {
  const queryClient = useQueryClient();
  const { setToken } = useAuthStore();

  const form = useForm<{
    newEmail: string;
    newEmailConfirmation: string;
    currentPassword: "";
  }>({
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      newEmail: "",
      newEmailConfirmation: "",
      currentPassword: "",
    },
  });

  const onEmailChange = useCallback(async () => {
    const { newEmail, currentPassword } = form.getValues();

    const url = `/change_email`;
    try {
      const resp = await AxiosInstance.post<{
        access_token: string;
        refresh_token: string;
      }>(url, {
        new_email: newEmail,
        current_password: currentPassword,
      });

      setToken({
        accessToken: resp?.data?.access_token,
        refreshToken: resp?.data?.refresh_token,
      });
      toast.success("You are successfully changed email!");
      await queryClient.refetchQueries({ queryKey: ["me"] });
    } catch (error) {
      toast.error("Password changing error");
    }

    form.reset({
      newEmail: "",
      newEmailConfirmation: "",
    });
  }, [form, queryClient, setToken]);

  return useMemo(
    () => ({
      emailForm: form,
      onSubmitEmail: form.handleSubmit(onEmailChange),
    }),
    [form, onEmailChange],
  );
};
