import React, { useMemo, useRef, useState } from "react";
import {
  ClientFilters,
  usePaginatedTrends,
} from "@/hooks/usePaginatedTrends.tsx";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";

import { Button } from "@/components/ui/button.tsx";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import ChartExpander from "@/components/ChartExpander/ChartExpander.tsx";
import { formatNumber } from "@/lib/utils.ts";
import { makeLinkFromAsin } from "@/helpers/helpers.ts";
import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";
import {
  ColumnSettingsByColumnNameInOrder,
  POPUP_COLUMNS,
} from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { ColumnsSettings } from "@/components/ColumnsSettings/ColumnsSettings.tsx";
import { TableFilterDialog } from "@/components/dialogs/TableFiltersDialog/TableFiltersDialog.tsx";
import { TopProductPopover } from "@/components/TopProductPopover/TopProductPopover.tsx";
import { SortDropdown } from "@/components/sort/SortDropdown.tsx";

import { TablePagination } from "@/components/table/TablePagination.tsx";
import {
  Cross1Icon,
  HeartIcon,
  PlayIcon,
  ResetIcon,
  TrashIcon,
} from "@radix-ui/react-icons";

import { useMe } from "@/hooks/useMe.tsx";
import { useUserSettings } from "@/hooks/useUserSettings.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import useMenuStore from "@/store/useMenuStore.tsx";
import SideMenuButton from "@/components/SideMenuButton.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { ONBOARDING_CLASSES } from "@/components/onboarding/useOnboarding.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";
import { ConfirmationPopup } from "@/components/ConfirmationPopup/ConfirmationPopup.tsx";
import { IS_STAGING } from "@/consts/consts";

export type OptionFilterReturn = {
  queryKey: ClientFilters;
  filterValue: string | number | boolean | null | object;
};

export type SortColumnOption = {
  columnKey: ClientFilters;
  sortValue: 1 | -1 | 0;
};

export const TrendsPage: React.FC = () => {
  const { me } = useMe({});
  const {
    paginatedData = [],
    tableHeadRow,
    addFilterHandler,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    fetchTable,
    // moveColumn,

    totalPagesCount,
    totalRows,
    currentPaginationPage,
    moveToPaginationPage,
    isPlaceholderData,
    changeListType,
    listType,
    isLoading,
    isFetching,
  } = usePaginatedTrends();

  const { setMenuShown, menuShown } = useMenuStore();
  const { deleteSavedSearchById } = useUserSettings();
  const queryClient = useQueryClient();

  const def = {
    id: "default",
    name: "Default search",
    applied_filters: [],
  };

  const {
    nextStep,
    currentOnboardingStep,
    startOnboarding,
    onboardingCanShow,
  } = useOnboardingStore();

  const savedSearches = useMemo(
    () => (me?.saved_searches ? [def, ...me?.saved_searches] : [def]),
    [me?.saved_searches],
  );

  const moveToPaginationPageHandler = (pageNum) => {
    localStorage.setItem(`last-page-${listType}`, pageNum);
    moveToPaginationPage(pageNum);
    setTimeout(() => scrollToTop(), 100);
  };

  const filteredData = paginatedData || [];

  const addSort = ({ sort }: { sort: SortColumnOption[] }) => {
    addSortHandler({ newSort: sort });
  };

  const {
    addTrendToBlockList,
    addTrendToWatchList,
    removeTrendsByListName,
    addFiltersList,
  } = useUserSettings();

  const addFilters = async ({ filters }: { filters: OptionFilterReturn[] }) => {
    await addFiltersList({ filterList: filters });
    addFilterHandler({ newFilters: filters });
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const addSelectedRowId = (id: number) => {
    if (selectedRows.some((r) => r === id)) {
      return;
    }

    setSelectedRows((prevRows) => [...prevRows, id]);
  };

  const [savedSearchFilters, setSavedSearchFilters] = useState([]);

  const removeSelectedRowId = (id: number) => {
    setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
  };

  const isNegative = (value: string) => {
    return value.startsWith("-");
  };

  const ValueDisplay = ({ value }) => {
    const negative = isNegative(value);
    const hasPercentage = value.includes("%");
    const numericValue = hasPercentage
      ? parseFloat(value.slice(0, -1))
      : parseFloat(value);
    const finalValue = formatNumber({
      number: numericValue,
      isPercentage: hasPercentage,
    });

    return (
      <p className={`${negative ? "text-red-800" : "text-green-800"}`}>
        {hasPercentage ? finalValue : value}
      </p>
    );
  };

  const scrollAreaRef = useRef<any>(null);

  const scrollToTop = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const trendTypeChangeHandler = (trendType) => {
    changeListType(trendType);

    const savedPage = localStorage.getItem(`last-page-${trendType}`);
    if (savedPage !== null) {
      moveToPaginationPage(JSON.parse(savedPage));
    } else {
      moveToPaginationPage(1);
    }
  };

  return (
    <div className="flex flex-col max-h-screen w-full">
      <div className="flex w-full flex-wrap items-center my-[12px] gap-4 px-[12px]">
        <FilterContainer>
          <SideMenuButton menuShown={menuShown} setMenuShown={setMenuShown} />
        </FilterContainer>
        {!onboardingCanShow && IS_STAGING && (
          <FilterContainer>
            <ConfirmationPopup
              text={"Start tour?"}
              confirmCb={() => {
                setMenuShown(false);
                startOnboarding();
              }}
            >
              <Button size={"sm"} variant="outline">
                <PlayIcon className={"mr-[6px]"} />
                Tour
              </Button>
            </ConfirmationPopup>
          </FilterContainer>
        )}
        <FilterContainer>
          <Select onValueChange={trendTypeChangeHandler}>
            <SelectTrigger className="w-[130px] h-8">
              <SelectValue defaultValue="trends" placeholder={"Trends"} />
            </SelectTrigger>
            <SelectContent size={"sm"}>
              <SelectItem value="trends">Trends</SelectItem>
              <SelectItem value="watch">Watch list</SelectItem>
              <SelectItem value="black">Black list</SelectItem>
            </SelectContent>
          </Select>
        </FilterContainer>
        {listType === "trends" && (
          <TableFilterDialog
            isLoading={isFetching}
            disabled={isFetching}
            savedSearchFilters={savedSearchFilters}
            addFilters={addFilters}
            onSearch={async () => {
              await fetchTable();
              moveToPaginationPageHandler(1);
            }}
          />
        )}

        {savedSearches.length !== 0 && listType === "trends" && (
          <FilterContainer>
            <Select
              onValueChange={async (f) => {
                const newFilters = [...f];
                await addFilters({ filters: newFilters });
                await queryClient.invalidateQueries({ queryKey: ["table"] });

                setSavedSearchFilters(newFilters);
                setTimeout(() => setSavedSearchFilters([]), 500);
              }}
            >
              <SelectTrigger className="w-fit">
                <SelectValue
                  className={"bg-purple-300 text-sm "}
                  placeholder={"Saved searches"}
                />
              </SelectTrigger>
              <SelectContent>
                {savedSearches.map((option) => (
                  <div className={"flex items-center"}>
                    <SelectItem
                      className={"cursor-pointer hover:bg-orange/10"}
                      key={option.id}
                      value={option.applied_filters}
                    >
                      {option.name}
                    </SelectItem>
                    {option.id !== "default" && (
                      <div className={"p-[5px]"}>
                        <TrashIcon
                          onClick={() =>
                            deleteSavedSearchById({ id: option.id })
                          }
                          className={"cursor-pointer hover:text-orange"}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </SelectContent>
            </Select>
          </FilterContainer>
        )}
        <FilterContainer>
          <ColumnsSettings
            hiddenRowsIds={hiddenRowsIds}
            hideColumn={hideColumn}
          />
        </FilterContainer>

        <div className={" flex w-fit  gap-[16px] "}>
          {listType === "trends" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  className={`${ONBOARDING_CLASSES.step10}`}
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToWatchList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                    if (
                      currentOnboardingStep === 10 ||
                      currentOnboardingStep === 11
                    ) {
                      nextStep();
                    }
                  }}
                >
                  <HeartIcon className={"mr-[6px]"} />
                  Add to watchlist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToBlockList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                  }}
                >
                  <TrashIcon className={"mr-[6px]"} />
                  Add to blacklist
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}

          {listType === "watch" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await removeTrendsByListName({
                      trends_ids: selectedRows,
                      listType: "watch",
                    });
                    setSelectedRows([]);
                    void fetchTable();
                  }}
                >
                  <ResetIcon className={"mr-[6px]"} />
                  Remove from watchlist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToBlockList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                  }}
                >
                  <TrashIcon className={"mr-[6px]"} />
                  Add to blacklist
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}

          {listType === "black" && (
            <div className={"flex w-fit gap-[16px]"}>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await removeTrendsByListName({
                      trends_ids: selectedRows,
                      listType: "black",
                    });
                    setSelectedRows([]);
                    void fetchTable();
                  }}
                >
                  <ResetIcon className={"mr-[6px]"} />
                  Remove from blacklist
                </Button>
              </FilterContainer>
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={async () => {
                    await addTrendToWatchList({ trends_ids: selectedRows });
                    setSelectedRows([]);
                    void fetchTable();
                  }}
                >
                  <HeartIcon className={"mr-[6px]"} />
                  Add to watchlist
                </Button>
              </FilterContainer>
              {selectedRows.length !== 0 && (
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      setSelectedRows([]);
                    }}
                  >
                    <Cross1Icon className={"mr-[6px]"} />
                    Cancel
                  </Button>
                </FilterContainer>
              )}
            </div>
          )}
        </div>
      </div>
      <ScrollArea
        ref={scrollAreaRef}
        className="max-w-[100vw] h-screen max-h-full"
        type={"always"}
      >
        <div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead
                  className={` sticky left-0 z-[29] border-t top-0 text-black text-center font-xs text-[12px] border-r-gray-300 bg-gray-50 border-r  py-[6px] min-w-[50px]`}
                >
                  Add
                </TableHead>
                {tableHeadRow.map((column, i) => {
                  const hiddenRow =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.hidden;
                  const sortable =
                    ColumnSettingsByColumnNameInOrder?.[column]?.sortable;
                  const isHidden = hiddenRow || hiddenRowsIds.includes(column);
                  const shownColumnName =
                    ColumnSettingsByColumnNameInOrder?.[column]?.shownName;

                  const tooltipText =
                    ColumnSettingsByColumnNameInOrder?.[column]?.tootipText;

                  const isNewKey = column === "new_key";
                  const isSvChange = column === "sv_change";
                  const isSearchTerm = column === "search_term";
                  return (
                    <TableHead
                      key={`${column}-${i}`}
                      className={`${isHidden || Object.keys(POPUP_COLUMNS).includes(column) ? "hidden" : ""} 
                      
                   text-black text-center font-xs font-normal border-r-gray-300 bg-gray-50 border-r border-t sticky top-[0px] !z-[1] py-[6px] min-w-[50px]`}
                    >
                      {isSvChange ? (
                        <>
                          <span className={"text-xs font-medium"}>
                            {shownColumnName || column}
                          </span>
                          <div
                            className={`${ONBOARDING_CLASSES["step8"]} flex flex-row justify-around mt-2`}
                          >
                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={500}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  12 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 12 months
                                  (0-12 months), compared to the previous 12
                                  months (12-24 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_12_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_12_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>

                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  6 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 6 months
                                  (0-6 months), compared to the previous 6
                                  months (6-12 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_6_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_6_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>

                            <div className="flex flex-col gap-0.5">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium text-text-2 cursor-help">
                                  3 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className={"w-max-96"}
                                >
                                  Change in search volume in the past 3 months
                                  (0-3 months), compared to the previous 3
                                  months (3-6 months ago).
                                </HoverCardContent>
                              </HoverCard>
                              <div className="flex flex-row gap-2">
                                <SortDropdown
                                  valueLabel={"%"}
                                  queryKey={"sv_increase_3_mnth_ratio"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                                <p>/</p>
                                <SortDropdown
                                  valueLabel={"#"}
                                  queryKey={"sv_increase_3_mnth_abs"}
                                  onSelect={addSort}
                                  options={isNewKey ? "bool" : "arrow"}
                                  showTriggerContent
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={"flex w-full items-center justify-center"}
                        >
                          <SortDropdown
                            sortable={sortable}
                            queryKey={column}
                            onSelect={addSort}
                            options={
                              isSearchTerm
                                ? "AZ"
                                : isNewKey && !isSearchTerm
                                  ? "bool"
                                  : "arrow"
                            }
                          >
                            {tooltipText ? (
                              <HoverCard openDelay={100}>
                                <HoverCardTrigger>
                                  <span className={"text-xs"}>
                                    {shownColumnName || column}
                                  </span>
                                </HoverCardTrigger>
                                {/*//TODO: sticky table issue*/}
                                <HoverCardContent
                                  side="left"
                                  className={" relative top-[2px] "}
                                >
                                  {tooltipText}
                                </HoverCardContent>
                              </HoverCard>
                            ) : (
                              <span className={"text-xs"}>
                                {shownColumnName || column}
                              </span>
                            )}
                          </SortDropdown>
                        </div>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody className={"min-h-full"}>
              {filteredData?.map((row, rowIndex) => {
                const entries: Record<string, any>[] = Object.entries(row);
                const asin = `${row["asin_1"] || row["asin_2"] || row["asin_3"]}`;
                const productLink = makeLinkFromAsin(asin);

                const id = row["search_term_id"];
                const inWatchList = me?.watch_list?.includes(id);

                const inBlackList = me?.black_list?.includes(id);
                const isOnboardingTrend = rowIndex === 4;
                return (
                  <TableRow
                    key={row["search_term_id"]}
                    style={{
                      contentVisibility: "auto",
                    }}
                    className={`
                     transition-opacity  
                     ${isOnboardingTrend && ONBOARDING_CLASSES["onboarding-trend-step-1"]} 
                     ${isFetching && isPlaceholderData && "animate-in opacity-50"}
                     ${inWatchList && "bg-green-600/20"} 
                     ${inBlackList && "bg-red-600/20"}`}
                  >
                    {
                      <TableCell
                        className={
                          "border-r-gray-300 border-r !font-xs !font-normal border-separate  sticky left-[0px] !z-[1]  bg-white"
                        }
                      >
                        <div
                          className={`${isOnboardingTrend && ONBOARDING_CLASSES.step9} flex justify-center items-center w-full h-full  `}
                        >
                          <Checkbox
                            checked={selectedRows.includes(
                              row["search_term_id"],
                            )}
                            className={"mr-2"}
                            onCheckedChange={(checked) => {
                              const id = row["search_term_id"];
                              if (checked) addSelectedRowId(id);
                              else removeSelectedRowId(id);
                              if (isOnboardingTrend) nextStep();
                            }}
                          />
                        </div>
                      </TableCell>
                    }

                    {entries.map((entry) => {
                      const cellValue = entry[1];
                      const columnName = entry[0];
                      const hiddenRow =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName]?.hidden;
                      const isHidden =
                        hiddenRow || hiddenRowsIds.includes(columnName);

                      const cellSettings =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName] || null;

                      const renderCell = () => {
                        if (columnName === "sv_change_12m") {
                          const vals = cellValue.split(" / ");

                          return (
                            <div
                              className={
                                "w-full h-[50px] flex flex-row justify-between max-w-[100px]"
                              }
                            >
                              <div
                                className={
                                  "flex justify-center items-center min-w-[49px] max-w-[49px]  text-xs"
                                }
                              >
                                <p>{vals[0]}</p>
                              </div>
                              <div
                                className={" flex min-h-full w-[1px] bg-black"}
                              />

                              <div
                                className={
                                  "flex  justify-center items-center  min-w-[49px] max-w-[49px] text-xs"
                                }
                              >
                                {vals[1]}
                              </div>
                            </div>
                          );
                        }
                        if (columnName === "sv_change") {
                          const yoyVals =
                            cellValue?.["sv_change_yoy"].split(" / ");
                          const sixMonthVals =
                            cellValue?.["sv_change_6m"].split(" / ");
                          const threeMonthVals =
                            cellValue?.["sv_change_3m"].split(" / ");

                          const isOnboardingRow = rowIndex === 4;
                          return (
                            <div
                              className={`w-full h-[50px] flex flex-row justify-between max-w-[270px] 
                                ${isOnboardingRow && ONBOARDING_CLASSES.step7}
                            `}
                            >
                              <div className="flex flex-row gap-1 justify-center items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={yoyVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={yoyVals[1]} />
                              </div>
                              <div className="flex flex-row justify-center gap-1 items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={sixMonthVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={sixMonthVals[1]} />
                              </div>
                              <div className="flex flex-row justify-center gap-1 items-center min-w-[89px] max-w-[89px] text-xs">
                                <ValueDisplay value={threeMonthVals[0]} />
                                <p>/</p>
                                <ValueDisplay value={threeMonthVals[1]} />
                              </div>
                            </div>
                          );
                        }
                        if (cellSettings !== null) {
                          if (cellSettings?.showDollar) {
                            return `${cellValue}$`;
                          }
                          if (cellSettings?.isCategory) {
                            const getTextAfterLastPipe = (text) => {
                              const parts = text.split("|");
                              return parts.length > 1
                                ? parts[parts.length - 1].trim()
                                : text;
                            };

                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <span
                                    className={
                                      "max-w-[150px] whitespace-break-spaces line-clamp-2 text-center"
                                    }
                                  >
                                    {getTextAfterLastPipe(cellValue)}
                                  </span>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }

                          if (cellSettings?.isSearchTerm) {
                            const link = `https://www.amazon.com/s?k=${cellValue}`;
                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <Button
                                    variant="link"
                                    className={`cursor-pointer text-blue-500 p-0 m-0   `}
                                  >
                                    <a
                                      href={link}
                                      target="_blank"
                                      className={`max-w-[150px] whitespace-break-spaces line-clamp-2 ${isOnboardingTrend && ONBOARDING_CLASSES.step2}`}
                                      rel="noopener noreferrer"
                                    >
                                      {cellValue}
                                    </a>
                                  </Button>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }
                          if (cellSettings?.isChart) {
                            return (
                              <ChartExpander
                                data={cellValue}
                                isOnboardingTrend={isOnboardingTrend}
                              />
                            );
                          }
                          if (
                            cellSettings?.isNumberValueWithKCut ||
                            cellSettings?.isPercent
                          ) {
                            return formatNumber({
                              number: cellValue,
                              isPercentage: cellSettings?.isPercent,
                            });
                          }

                          if (cellSettings?.yesNoValue) {
                            return cellValue ? "Yes" : "No";
                          }

                          if (
                            cellSettings?.isLink &&
                            cellSettings?.hasProductLink
                          ) {
                            if (cellValue === "0") return "-";

                            return (
                              <Button
                                variant="link"
                                className={"text-blue-500 cursor-pointer"}
                              >
                                <a
                                  href={makeLinkFromAsin(cellValue)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ></a>
                              </Button>
                            );
                          }
                          if (cellSettings?.isLink && !cellSettings?.isImg) {
                            return (
                              <Button
                                variant="link"
                                className={"cursor-pointer text-blue-500"}
                              >
                                <a
                                  href={cellValue}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {cellValue}
                                </a>
                              </Button>
                            );
                          }
                        }

                        if (
                          cellSettings?.isImg &&
                          cellSettings?.hasProductLink
                        ) {
                          const imageLink = cellValue;

                          return (
                            <TopProductPopover
                              row={row}
                              isOnboarding={isOnboardingTrend}
                            >
                              <Button
                                variant="link"
                                className={`cursor-pointer text-blue-500 h-fit ${isOnboardingTrend && ONBOARDING_CLASSES.step3}`}
                              >
                                <a
                                  href={productLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {imageLink !== "None" ? (
                                    <img
                                      src={imageLink}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  ) : (
                                    <img
                                      src={"placeholder-image.png"}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  )}
                                </a>
                              </Button>
                            </TopProductPopover>
                          );
                        }
                        return cellValue;
                      };

                      return (
                        <TableCell
                          className={`${(isHidden || Object.keys(POPUP_COLUMNS).includes(columnName)) && "hidden"} 
                        border-r-gray-300 border-r !font-xs !font-normal`}
                        >
                          <div className="flex flex-col items-center justify-center ">
                            {renderCell()}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <ScrollBar
            forceMount
            orientation="vertical"
            className={"z-[20] !bg-gray-500/50 fixed "}
          />

          <ScrollBar
            orientation="horizontal"
            className="z-[20] !bg-gray-500/50"
          />
        </div>
      </ScrollArea>
      <div className="flex py-6 w-screen bg-gray-50 items-center text-black">
        <div className="absolute right-4">
          <span className="text-md font-medium">
            {paginatedData.length} of{" "}
            {typeof totalRows === "number"
              ? totalRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : totalRows}{" "}
          </span>
          <span className="text-sm font-regular">total niches</span>
        </div>
        <div className="absolute flex left-[44%]">
          <TablePagination
            totalPagesCount={totalPagesCount}
            currentPaginationPage={currentPaginationPage}
            moveToPaginationPage={moveToPaginationPageHandler}
          />
        </div>
      </div>
    </div>
  );
};
