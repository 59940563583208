import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { SortColumnOption } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends.tsx";

type OptionsType = "AZ" | "arrow" | "bool";

interface SelectFilterProps extends PropsWithChildren {
  options?: OptionsType;
  onSelect: ({ sort }: { sort: SortColumnOption[] }) => void;
  queryKey: ClientFilters;
  valueLabel?: string;
  labelColor?: string;
  showTriggerContent?: boolean;
}

const LABELS: Record<OptionsType, any[]> = {
  AZ: [
    { label: "A → Z", value: 1 },
    { label: "Z → A", value: -1 },
    { label: "Default", value: 0 },
  ],
  arrow: [
    { label: "Highest first", value: -1 },
    { label: "Lowest first", value: 1 },
    { label: "Default", value: 0 },
  ],
  bool: [
    { label: "New first", value: -1 },
    { label: "New last", value: 1 },
    { label: "Default", value: 0 },
  ],
};

export const SortDropdown: FC<SelectFilterProps> = ({
  queryKey,
  options = "arrow",
  onSelect,
  children,
  valueLabel = "",
  labelColor,
  showTriggerContent = false,
  sortable = true,
}) => {
  const optionsByOptionsKey = LABELS[options];
  const [isOpen, setIsOpen] = useState(false);

  const [defaultValue, setDefaultValue] = useState(optionsByOptionsKey[2]);

  useEffect(() => {
    const key = `${queryKey}-sort`;
    const savedQuery = localStorage.getItem(key);
    if (savedQuery) {
      const parsed = JSON.parse(savedQuery);

      setDefaultValue(parsed);
      onSelect({ sort: [{ columnKey: queryKey, sortValue: parsed.value }] });
    }
  }, []);

  return (
    <DropdownMenu open={isOpen}>
      <DropdownMenuTrigger onClick={() => setIsOpen(true)} disabled={!sortable}>
        <div className={"flex justify-between items-center"}>
          {showTriggerContent && (
            <div
              className={"flex flex-row text-xs font-medium whitespace-nowrap"}
            >
              <div className="h-5 w-5 flex justify-center items-center rounded-sm-native bg-gray-50 transition-all hover:brightness-90">
                <p className={`${labelColor}`}>{valueLabel}</p>
              </div>
            </div>
          )}
          <p className="font-medium">{children}</p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={"min-w-[0]"}
        onInteractOutside={() => {
          setIsOpen(false);
        }}
      >
        <DropdownMenuLabel>Sort</DropdownMenuLabel>
        {/*<DropdownMenuSeparator />*/}
        {optionsByOptionsKey.map((o, i) => (
          <DropdownMenuCheckboxItem
            key={i}
            checked={o.label === defaultValue.label}
            onCheckedChange={() => {
              setDefaultValue(o);
              localStorage.setItem(`${queryKey}-sort`, JSON.stringify(o));

              onSelect({
                sort: [{ columnKey: queryKey, sortValue: o.value }],
              });
            }}
          >
            {o.label || "1"}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
