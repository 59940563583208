import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card.tsx";
import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { EMAIL_PATTERN, PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { Button } from "@/components/ui/button.tsx";
import { useRegistrationForm } from "@/pages/LoginPage/useRegistraton.tsx";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const RegistrationPage = () => {
  const { form, onSubmit } = useRegistrationForm({
    cb: () => setShowPaymentForm(true),
  });

  const { paymentType } = useParams();
  const savedPayment = paymentType === "monthly" ? "monthly" : "yearly";

  const getPriceId = () => {
    const dev = {
      yearly: "pri_01j6254vaenyb5tn1vbzwcjaya",
      monthly: "pri_01j6253e8cex6rxj46mhwe3jfd",
    };

    const prod = {
      yearly: "pri_01j624sw107gqf66bkrz74ydkk",
      monthly: "pri_01j624vagdt9c4v4n34p7vsth7",
    };

    return import.meta.env.VITE_ENV === "production"
      ? prod[savedPayment]
      : dev[savedPayment];
  };

  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const email = form.watch("email");

  useEffect(() => {
    if (showPaymentForm) {
      const isProd = import.meta.env.VITE_ENV === "production";
      initializePaddle({
        environment: isProd ? "production" : "sandbox",
        token: import.meta.env.VITE_PADDLE_TOKEN,
        checkout: {
          settings: {
            displayMode: "inline",
            successUrl: isProd
              ? "https://app.nextbigniche.ai/trends"
              : "https://staging-app.nextbigniche.ai/trends",
            showAddDiscounts: false,
          },
        },
      }).then((paddleInstance: Paddle | undefined) => {
        const priceId = getPriceId();
        paddleInstance?.Checkout.open({
          customer: {
            email,
          },
          settings: {
            theme: "light",
            frameTarget: "test-payment",
            frameStyle: "position:relative;min-width:486px;",
          },
          customData: {
            email,
          },
          items: [{ priceId, quantity: 1 }],
        });
      });
    }
  }, [email, getPriceId, showPaymentForm]);

  const shownPrice = savedPayment === "yearly" ? "948.00" : "$99.00";

  return (
    <div className="flex min-w-screen min-h-screen justify-center items-center">
      <Card
        className={
          "min-w-[500px] min-h-[500px] flex flex-col text-black justify-between p-[16px] h-full rounded-r-none border-r-0 shadow-r-none  "
        }
      >
        <div className={"flex w-full gap-4"}>
          <div className={"flex flex-col w-full gap-[1px]"}>
            <div
              className={"flex w-full border-b-2 border-orange rounded-full"}
            />
            <p className="text-gray-400 text-[12px]">Step 1</p>
            <p className={"text-sm"}>Create Account</p>
          </div>
          <div className={"flex flex-col w-full gap-[1px]"}>
            <div
              className={`flex w-full border-b-2  ${!showPaymentForm ? "border-orange/40" : "border-purple-400"}  rounded-full`}
            />
            <p
              className={`text-gray-400 text-[12px] ${!showPaymentForm && "text-gray-300"}`}
            >
              Step 2
            </p>
            <p className={`text-sm  ${!showPaymentForm && "text-gray-300"}`}>
              Payment Details
            </p>
          </div>
        </div>
        {showPaymentForm ? (
          <div className={"min-h-[600px]"}>
            <div className={"test-payment"} />
          </div>
        ) : (
          <Card className="!border-none flex flex-col justify-between !shadow-none rounded-r-none border-r-0">
            <CardHeader></CardHeader>
            <CardContent>
              <form>
                <div className="grid w-full items-center gap-[26px]">
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Email</Label>
                    <FormInput
                      form={form}
                      fieldName={"email"}
                      validation={{
                        pattern: {
                          message: "Please, type valid email",
                          value: EMAIL_PATTERN,
                        },
                        required: "Email is required",
                      }}
                    />
                  </div>

                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Password</Label>
                    <FormInput
                      form={form}
                      validation={{
                        pattern: {
                          message: "password validation",
                          value: PASSWORD_PATTERNS,
                        },
                        required: "Password is required",
                        minLength: { value: 6, message: "Short password" },
                      }}
                      fieldName="password"
                      type={"password"}
                    />
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        )}
        <CardFooter className="flex flex-col gap-[10px] justify-between">
          <Button onClick={onSubmit} className="w-full">
            Next
          </Button>
        </CardFooter>
      </Card>
      <Card
        className={
          "min-w-[500px] h-[500px] p-[16px] text-black rounded-l-none border-l-0 shadow-l-none  "
        }
      >
        <CardContent className="flex flex-col h-full justify-between">
          <div className="flex flex-col h-auto gap-1">
            <p className="text-[24px] font-bold">Your Subscription</p>
            <span className="border" />
            <div className="flex justify-between w-full gap-[1px] text-muted-foreground">
              <div className="flex flex-col">
                <p className="font-semibold capitalize">{savedPayment}</p>
                {/*<p>Billed Annually</p>*/}
              </div>
              <p className="font-medium">
                ${savedPayment === "yearly" ? 79 : 99} / month
              </p>
            </div>
          </div>
          <p className="font-medium max-w-md">
            {savedPayment === "monthly" &&
              "Launch your next product. Pay as you go, cancel anytime."}
            {savedPayment === "yearly" &&
              "Spot new opportunities before everyone else and beat the market."}
          </p>
          <div className="flex w-full flex-col h-auto">
            <div className="flex w-full max-w-sm flex-col">
              <ul className="list-disc pl-5 gap-1 flex flex-col text-light">
                <li>
                  120 thousand rising search terms, ranging from sustainable
                  growth to exploding trends
                </li>
                <li>
                  A comprehensive list of growing search terms with
                  easy-to-enter first page
                </li>
                <li>
                  All important metrics on one screen, including the top-3
                  products in each niche
                </li>
                <li>
                  Just a couple of hours a month to stay updated with market
                  changes
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-between w-full gap-4 h-auto flex-col">
            <div>
              <div className="flex w-full justify-between">
                <p className="font-bold">Total After Trial</p>
                <p className="font-bold"> {shownPrice}</p>
              </div>
              <div className="flex w-full justify-between">
                <p className="font-bold">Total Due Today</p>
                <p className="font-bold text-accent-1"> $0.00</p>
              </div>
            </div>
            <p className="text-xs text-muted-foreground text-gray-600">
              We'll bill you every{" "}
              {savedPayment === "yearly" ? "year" : "month"}, unless you cancel.
              You can cancel any time.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
