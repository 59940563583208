import { create } from "zustand";

interface OnboardingStoreState {
  currentOnboardingStep: number;
  isReady: boolean;
  nextStep: () => void;
  stop: boolean;
  setIsReady: (isReady: boolean) => void;
  setStop: (stop: boolean) => void;
  onboardingCanShow: boolean;
  startOnboarding: (onboardingCanShow?: boolean) => void;
}

const baseState = {
  currentOnboardingStep: 0,
  isReady: false,
  stop: false,
  onboardingCanShow: false,
};

export const useOnboardingStore = create<OnboardingStoreState>()((set) => ({
  ...baseState,
  nextStep: () =>
    set((state: OnboardingStoreState) => ({
      isReady: false,
      currentOnboardingStep: state.currentOnboardingStep + 1,
    })),
  setIsReady: (isReady = true) =>
    set(() => ({
      isReady,
    })),
  setStop: (stop = true) =>
    set(() => ({
      stop,
    })),
  startOnboarding: (onboardingCanShow = true) =>
    set(() => ({
      onboardingCanShow,
    })),
}));
