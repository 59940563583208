import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { EMAIL_PATTERN, PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { useChangePassword } from "@/pages/SettingsPage/hooks/useChangePassword.tsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useChangeEmail } from "@/pages/SettingsPage/hooks/useChangeEmail.tsx";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import useSurveyStore from "@/store/useSurveyStore.tsx";
import SurveyModal from "@/components/SurveyModal.tsx";
import { Dialog } from "@/components/ui/dialog.tsx";
export const Settings = () => {
  const { form, onSubmit } = useChangePassword();
  const { emailForm, onSubmitEmail } = useChangeEmail();
  const { formPage, setFormPage, formPageIsOpen, setFormPageIsOpen } =
    useSurveyStore();

  return (
    <div className="p-4 w-full">
      <div className="text-xl font-bold">Settings</div>
      <div className="flex flex-col translate w-full">
        <Collapsible className="w-[350px] space-y-2">
          <Card className="!border-none !shadow-none w-1/4 min-w-[300px]">
            <CollapsibleTrigger asChild>
              <CardHeader className="font-medium text-center !p-2">
                <Button className="max-w-52" size={"sm"} variant={"outline"}>
                  Change password
                </Button>
              </CardHeader>
            </CollapsibleTrigger>

            <CollapsibleContent>
              <CardContent>
                <form className="flex flex-col gap-5">
                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      Current password
                    </Label>
                    <FormInput
                      form={form}
                      validation={{
                        required: "Password is required",
                        minLength: { value: 6, message: "Short password" },
                      }}
                      fieldName="currentPassword"
                      type={"currentPassword"}
                      className="!h-10"
                    />
                  </div>

                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      New password
                    </Label>
                    <FormInput
                      form={form}
                      validation={{
                        pattern: {
                          message: "password validation",
                          value: PASSWORD_PATTERNS,
                        },
                        required: "Password is required",
                        minLength: { value: 6, message: "Short password" },
                        validate: (value) => {
                          if (value === form.watch("currentPassword"))
                            return "new and old passwords must not be the same";
                          return (
                            value === form.watch("newPasswordConfirmation") ||
                            "New passwords do not match"
                          );
                        },
                      }}
                      className="!h-10"
                      fieldName="newPassword"
                      type={"newPassword"}
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      Confirm new password
                    </Label>
                    <FormInput
                      form={form}
                      validation={{
                        required: "Password is required",
                        validate: (value) => {
                          return (
                            value === form.watch("newPassword") ||
                            "New passwords do not match"
                          );
                        },
                      }}
                      fieldName="newPasswordConfirmation"
                      type="newPasswordConfirmation"
                      className="!h-10"
                    />
                  </div>
                </form>
              </CardContent>
              <CardFooter className="flex flex-col gap-[10px] justify-between">
                <Button
                  onClick={onSubmit}
                  className="w-fit h-8 self-start"
                  variant="outline"
                  size="sm"
                >
                  Confirm change
                </Button>
              </CardFooter>
            </CollapsibleContent>
          </Card>
        </Collapsible>

        <Collapsible className="w-[350px] space-y-2">
          <Card className="!border-none !shadow-none w-1/4 min-w-[300px]">
            <CollapsibleTrigger asChild>
              <CardHeader className="font-medium text-center !p-2">
                <Button className="max-w-52" size={"sm"} variant={"outline"}>
                  Change email
                </Button>
              </CardHeader>
            </CollapsibleTrigger>

            <CollapsibleContent className="space-y-2">
              <CardContent>
                <form className="flex flex-col gap-5">
                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      New email
                    </Label>
                    <FormInput
                      form={emailForm}
                      validation={{
                        pattern: {
                          message: "Please, type valid email",
                          value: EMAIL_PATTERN,
                        },
                        required: "Email is required",
                      }}
                      fieldName="newEmail"
                      className="!h-10"
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      Email confirmation
                    </Label>
                    <FormInput
                      form={emailForm}
                      fieldName={"newEmailConfirmation"}
                      validation={{
                        required: "Emails do not match",
                        validate: (value) => {
                          return (
                            value === emailForm.watch("newEmail") ||
                            "Emails do not match"
                          );
                        },
                      }}
                    />
                  </div>

                  <div className="flex flex-col space-y-1.5">
                    <Label className="text-muted-foreground text-xs">
                      Current password
                    </Label>
                    <FormInput
                      form={emailForm}
                      fieldName={"currentPassword"}
                      validation={{
                        required: "Passsword is required",
                      }}
                    />
                  </div>
                </form>
              </CardContent>
              <CardFooter className="flex flex-col gap-[10px] justify-between">
                <Button
                  onClick={onSubmitEmail}
                  className="w-fit h-8 self-start"
                  variant="outline"
                  size="sm"
                >
                  Change email
                </Button>
              </CardFooter>
            </CollapsibleContent>
          </Card>
        </Collapsible>
        <Dialog open={formPageIsOpen}>
          <SurveyModal
            formPage={formPage}
            setFormPage={setFormPage}
            setFormPageIsOpen={setFormPageIsOpen}
          />
        </Dialog>
        <Button
          disabled
          className="max-w-52 ml-2 mt-2"
          size="sm"
          onClick={() => setFormPageIsOpen(true)}
        >
          Take a survey
        </Button>
      </div>
    </div>
  );
};
