import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import CheckboxTree from "react-checkbox-tree";
import { Button } from "@/components/ui/button.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useCategoriesList } from "@/hooks/useCategoriesList.tsx";
import { FC, useCallback, useEffect, useState } from "react";
import { DialogClose } from "@radix-ui/react-dialog";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends.tsx";
import { useMe } from "@/hooks/useMe.tsx";
export type Category = {
  category: string;
  child: Category[];
  id: number;
};
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  CheckboxIcon,
  MinusIcon,
  PlusIcon,
  SquareIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import { isObject, toNumber } from "lodash";
import { isEmptyObject } from "@/lib/utils.ts";
interface CategoryFilterProps {
  onSave: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  queryKey: ClientFilters;
  popoverContent?: string;
  dropFilter?: boolean;
  selectedSavedSearch: any[];
}

export const CategoryFilter: FC<CategoryFilterProps> = ({
  onSave,
  queryKey,
  dropFilter,
  selectedSavedSearch,
}) => {
  const { categories } = useCategoriesList();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  function findPath(categories, selectedValues) {
    const result = {};

    const findValuePath = (category, path) => {
      if (selectedValues.includes(String(category.value))) {
        const currentPath = path.concat(category.value);
        let currentLevel = result;

        currentPath.forEach((value, index) => {
          if (!currentLevel[value]) {
            currentLevel[value] = index === currentPath.length - 1 ? {} : {};
          }
          currentLevel = currentLevel[value];
        });
      }

      if (category.children) {
        for (const child of category.children) {
          findValuePath(child, path.concat(category.value));
        }
      }
    };

    for (const category of categories) {
      findValuePath(category, []);
    }

    return result;
  }

  const { me } = useMe({});
  const filterList = me?.filter_list || [];

  function extractValues(pathObject) {
    const result = [];

    const extract = (obj) => {
      for (const key in obj) {
        const formattedKey = String(key);
        result.push(formattedKey); // Добавляем текущий ключ в массив результата
        extract(obj[formattedKey]); // Рекурсивно обрабатываем вложенные объекты
      }
    };

    extract(pathObject);
    return result;
  }

  const onSaveHandler = useCallback(() => {
    if (checked.length === 0) {
      onSave({
        filters: [
          {
            filterValue: null,
            queryKey,
          },
        ],
      });
      return;
    }

    onSave({
      filters: [
        {
          filterValue: findPath(categories, checked),
          queryKey,
        },
      ],
    });
  }, [categories, checked, onSave, queryKey]);

  useEffect(() => {
    if (filterList.length !== 0) {
      const foundFilter = filterList?.find((f) => f.queryKey === queryKey);
      if (foundFilter !== undefined) {
        setChecked(extractValues(foundFilter.filterValue));

        if (isObject(foundFilter) && isEmptyObject(foundFilter)) {
          onSave({
            filters: [
              {
                filterValue: null,
                queryKey,
              },
            ],
          });
          return;
        }
        onSave({
          filters: [
            {
              filterValue: foundFilter.filterValue,
              queryKey,
            },
          ],
        });
      }
    }
  }, []);

  useEffect(() => {
    const appliedFilter = selectedSavedSearch?.find(
      (f) => f?.queryKey === queryKey,
    );

    if (appliedFilter !== undefined) {
      setChecked(extractValues(appliedFilter.filterValue));

      if (isObject(appliedFilter) && isEmptyObject(appliedFilter)) {
        onSave({
          filters: [
            {
              filterValue: null,
              queryKey,
            },
          ],
        });
        return;
      }
      onSave({
        filters: [
          {
            filterValue: appliedFilter.filterValue,
            queryKey,
          },
        ],
      });
    }
  }, [onSave, queryKey, selectedSavedSearch]);

  useEffect(() => {
    if (dropFilter) {
      setChecked([]);
      onSave({ filters: [{ queryKey, filterValue: "" }] });
    }
  }, [dropFilter]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size={"sm"}>
          Select Categories
        </Button>
      </DialogTrigger>
      <DialogContent
        className={"h-[calc(100vh-100px)] px-6 pt-6 pb-4 rounded-sm"}
      >
        <DialogHeader>
          <DialogTitle>Select Categories</DialogTitle>
        </DialogHeader>

        <ScrollArea className="h-[calc(100vh-250px)]">
          <CheckboxTree
            checkModel={"all"}
            icons={{
              check: <CheckboxIcon />,
              uncheck: <SquareIcon />,
              halfCheck: <Check />,
              expandClose: <PlusIcon />,
              expandOpen: <MinusIcon />,
              // expandAll: <StarIcon />,
              // collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
              parentClose: <></>,
              parentOpen: <></>,
              leaf: <StarIcon />,
            }}
            nodes={categories}
            nameAsArray
            nativeCheckboxes
            showExpandAll
            showNodeIcon={false}
            checked={checked}
            expanded={expanded}
            onCheck={setChecked}
            onExpand={setExpanded}
          />
        </ScrollArea>

        <DialogFooter>
          <div className={"flex justify-between w-full items-center"}>
            {/*<div className="text-gray-900">*/}
            {/*  {selectedParentCount} categories and {selectedSubcategoryCount}{" "}*/}
            {/*  subcategories selected*/}
            {/*</div>*/}
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setChecked([]);
              }}
            >
              Clear all
            </Button>

            <DialogClose onClick={onSaveHandler}>
              <Button size="sm" variant="accent">
                Save and exit
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
