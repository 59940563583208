import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  accentBorder?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ accentBorder, className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          `${accentBorder ? "focus:border-b-accent-1" : "focus:border-accent-1"} flex h-9 w-full rounded-md-native border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 placeholder:font-light focus-visible:outline-none  focus-visible:ring-transparent disabled:cursor-not-allowed disabled:opacity-50`,
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
