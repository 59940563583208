import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { TrendsPaginatedResponse } from "@/types/types.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OptionFilterReturn,
  SortColumnOption,
} from "@/pages/TrendsPage/TrendsPage.tsx";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { toast } from "sonner";
import { isObject } from "lodash";
import { useMe } from "@/hooks/useMe.tsx";
import AxiosInstance from "@/services/axios-instance.ts";

export type ClientFilters = Filters | FilteredColumns;

export type Filters =
  | "sort"
  | "order"
  | "adult_product"
  | "category_list"
  | "brand"
  | "price_min"
  | "price_max"
  | "rating_min"
  | "rating_max"
  | "year_quarter_ratio_min"
  | "year_quarter_ratio_max"
  | "seasonal_split_q2_23_24_min"
  | "seasonal_split_q2_23_24_max"
  | "half_year_ratio_min"
  | "half_year_ratio_max"
  | "q2_23_sv_ratio_min"
  | "q2_23_sv_ratio_max"
  | "q3_23_sv_ratio_min"
  | "q3_23_sv_ratio_max"
  | "q4_23_sv_ratio_min"
  | "q4_23_sv_ratio_max"
  | "q1_24_sv_ratio_min"
  | "q1_24_sv_ratio_max"
  | "q2_24_sv_ratio_min"
  | "q2_24_sv_ratio_max"
  | "sv_increase_yoy_ratio_min"
  | "sv_increase_yoy_ratio_max"
  | "sv_increase_3_mnth_ratio_min"
  | "sv_increase_3_mnth_ratio_max"
  | "sv_increase_6_mnth_ratio_min"
  | "sv_increase_6_mnth_ratio_max"
  | "total_click_share_min"
  | "total_click_share_max"
  | "click_share_sum_min"
  | "click_share_sum_max"
  | "convershion_share_sum_min"
  | "convershion_share_sum_max"
  | "lwr_min"
  | "lwr_max"
  | "reviews_min"
  | "reviews_max"
  | "avg_ly_sv_min"
  | "avg_ly_sv_max"
  | "avg_sv_q2_23_min"
  | "avg_sv_q2_23_max"
  | "avg_sv_q3_23_min"
  | "avg_sv_q3_23_max"
  | "avg_sv_q4_23_min"
  | "avg_sv_q4_23_max"
  | "avg_sv_q1_24_min"
  | "avg_sv_q1_24_max"
  | "avg_sv_q2_24_min"
  | "avg_sv_q2_24_max"
  | "sv_increase_yoy_abs_min"
  | "sv_increase_yoy_abs_max"
  | "sv_increase_3_mnth_abs_min"
  | "sv_increase_3_mnth_abs_max"
  | "sv_increase_6_mnth_abs_min"
  | "sv_increase_6_mnth_abs_max"
  | "search_term"
  | "main_category"
  | "brand_1"
  | "brand_2"
  | "brand_3"
  | "context_free_last_category"
  | "root_category"
  | "browse_classification_display_name"
  | "website_display_group_name"
  | "sv_increase_3_mnth_abs"
  | "sv_increase_6_mnth_abs"
  | "sv_increase_12_mnth_abs"
  | "sv_increase_3_mnth_ratio"
  | "sv_increase_6_mnth_ratio"
  | "sv_increase_12_mnth_ratio";

export type PopupColumns =
  | "asin_1"
  | "asin_2"
  | "asin_3"
  | "brand_1"
  | "brand_2"
  | "brand_3"
  | "click_share_1"
  | "click_share_2"
  | "click_share_3"
  | "conv_share_1"
  | "conv_share_2"
  | "conv_share_3"
  | "img_link_1"
  | "img_link_2"
  | "img_link_3";

export type FilteredColumns =
  | "sv_change_3m"
  | "sv_change_6m"
  | "sv_change_12m"
  | "top_product_category"
  | "top_product_img"
  | "adult_product"
  | "top_product_image"
  | "BSR_context_free_last_category"
  | "BSR_root_category"
  | "avg_2ly_sv"
  | "avg_ly_sv"
  | "avg_sv_q1_24"
  | "avg_sv_q2_23"
  | "avg_sv_q2_24"
  | "avg_sv_q3_23"
  | "avg_sv_q4_23"
  | "brand"
  | "browse_classification_display_name"
  | "category_1"
  | "category_2"
  | "category_3"
  | "classification_id"
  | "click_share_sum"
  | "context_free_last_category"
  | "convershion_share_sum"
  | "dates"
  | "sv_change"
  | "global_search_count"
  | "graph"
  | "half_year_ratio"
  | "id"
  | "last_parsing_date"
  | "last_week_rank"
  | "main_category"
  | "months_with_ranking"
  | "months_without_ranking"
  | "new_key"
  | "node_id_context_free_last_category"
  | "nonzero_sequence"
  | "page_search_count"
  | "price_max"
  | "price_mean"
  | "price_median"
  | "price_min"
  | "q1_24_sv_ratio"
  | "q2_23_sv_ratio"
  | "q2_24_sv_ratio"
  | "q3_23_sv_ratio"
  | "q4_23_sv_ratio"
  | "rating_max"
  | "rating_mean"
  | "rating_median"
  | "rating_min"
  | "reviews_max"
  | "reviews_mean"
  | "reviews_median"
  | "reviews_min"
  | "root_category"
  | "search_link"
  | "search_term"
  | "seasonal_search_term"
  | "seasonal_split_q2_23_24"
  | "sv_increase_yoy_abs"
  | "sv_increase_yoy_ratio"
  | "total_click_share"
  | "website_display_group_name"
  | "year_quarter_ratio"
  | Filters;

export type ListType = "trends" | "watch" | "black";
export const usePaginatedTrends = () => {
  const [listType, setListType] = useState<ListType>("trends");

  const queryClient = useQueryClient();

  const [appliedFilters, setAppliedFilters] = useState<OptionFilterReturn[]>(
    [],
  );

  const { me } = useMe({});
  const filterList = me?.filter_list;

  useEffect(() => {
    setAppliedFilters(filterList || []);
  }, [filterList]);

  const [appliedSort, setAppliedSort] = useState<SortColumnOption[]>([]);

  const [page, setPage] = useState<number>(1);

  const moveToPaginationPage = (num: number) => {
    setPage(num);
  };

  const hideColumn = (col: FilteredColumns) => {
    if (hiddenRowsIds.includes(col)) {
      const filteredRowsIds = hiddenRowsIds.filter((f) => f !== col);
      setHiddenRowsIds(filteredRowsIds);
      localStorage.setItem("hidden-columns", JSON.stringify(filteredRowsIds));
      return;
    }
    const newHiddenRows = [...hiddenRowsIds, col];
    setHiddenRowsIds(newHiddenRows);
    localStorage.setItem("hidden-columns", JSON.stringify(newHiddenRows));
  };

  const [hiddenRowsIds, setHiddenRowsIds] = useState<FilteredColumns[]>([]);

  useEffect(() => {
    if (listType !== "trends") {
      setAppliedFilters([]);
      setAppliedSort([]);
    }
  }, [listType]);

  useEffect(() => {
    const columns = localStorage.getItem("hidden-columns");
    if (columns) {
      setHiddenRowsIds(JSON.parse(columns));
    }
  }, []);

  const filtersQuery = useMemo(
    () =>
      appliedFilters
        .filter((f) => f.queryKey !== "category_list")
        .map(
          (f) =>
            `${f.queryKey}=${isObject(f.filterValue) ? JSON.stringify(f.filterValue) : f.filterValue}&`,
        )
        .join(""),
    [appliedFilters],
  );

  const sortQuery =
    appliedSort.length !== 0
      ? appliedSort?.map((f) => `${f.columnKey}:${f.sortValue}`).join(",")
      : "";

  const addSortHandler = ({ newSort }: { newSort: SortColumnOption[] }) => {
    setAppliedSort((prevSort) => {
      const filteredPrevFilters = prevSort.filter(
        (sort) => !newSort.some((newS) => newS.columnKey === sort.columnKey),
      );

      const validNewFilters = newSort.filter((s) => s.sortValue !== 0);

      return [...validNewFilters, ...filteredPrevFilters];
    });
  };

  const addFilterHandler = ({
    newFilters,
  }: {
    newFilters: OptionFilterReturn[];
  }) => {
    if (newFilters.length === 0) {
      setAppliedFilters([]);
      return;
    }

    setAppliedFilters(newFilters);
  };
  const changeListType = useCallback(
    (type: ListType) => {
      // localStorage.setItem(`last-page-${listType}`, page);
      setListType(type);
      void queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    [listType, page, queryClient],
  );

  // useEffect(() => {
  //   const savedPage = localStorage.getItem(`last-page-${listType}`);
  //   if (savedPage !== null) {
  //     moveToPaginationPage(JSON.parse(savedPage));
  //   }
  // }, []);

  //TODO: edit this with https://tanstack.com/query/v5/docs/framework/react/guides/infinite-queries
  const {
    isPlaceholderData,
    isLoading,
    isFetched,
    isFetching,
    data: paginatedData,
    refetch,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [`table`, sortQuery, page, listType, filtersQuery],
    queryFn: async ({ signal }) => {
      try {
        const url = ({ page }: { page: number }) =>
          `/trends?limit=85&list_type=${listType}&page=${page}&${filtersQuery}${sortQuery ? `sort=${sortQuery}` : ""}`;
        const resp = await AxiosInstance.get<TrendsPaginatedResponse>(
          url({ page }),
          {
            signal,
          },
        );

        if (resp?.data?.trends?.length === 0 || !resp?.data?.trends) {
          toast.error("No trends, try another filters");
        }

        const sortedTrendsByColumnsOrder = resp?.data?.trends?.map((item) => {
          const sortedItem: any = {};
          Object.keys(ColumnSettingsByColumnNameInOrder).forEach((col) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            sortedItem[col] = item[col];
          });

          return sortedItem;
        });

        const returnedData = {
          ...resp?.data,
          trends: sortedTrendsByColumnsOrder,
        };

        if (resp.data.totalPagesCount > 1) {
          void queryClient.prefetchQuery({
            queryKey: [`table`, sortQuery, page + 1, listType, filtersQuery],
            queryFn: async ({ signal }) => {
              const resp = await AxiosInstance.get<TrendsPaginatedResponse>(
                url({ page: page + 1 }),
                {
                  signal,
                },
              );

              const sortedTrendsByColumnsOrderCahce = resp?.data?.trends?.map(
                (item) => {
                  const sortedItem: any = {};
                  Object.keys(ColumnSettingsByColumnNameInOrder).forEach(
                    (col) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      sortedItem[col] = item[col];
                    },
                  );

                  return sortedItem;
                },
              );

              const chacheReturnedData = {
                ...resp?.data,
                trends: sortedTrendsByColumnsOrderCahce,
              };

              return chacheReturnedData;
            },
            staleTime: 60000,
          });
        }

        return returnedData;
        // return resp?.data;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
    staleTime: 0,
    enabled: me?.subscription,
  });

  // Extract the keys and sort them based on columnsOrder
  const tableHeadRow = Object.keys(ColumnSettingsByColumnNameInOrder).map(
    (col) => col,
  ) as FilteredColumns[];

  return {
    changeListType,
    currentPaginationPage: page,
    fetchTable: refetch,
    paginatedData: paginatedData?.trends,
    tableHeadRow,
    isPlaceholderData,
    isLoading,
    isFetched,
    isFetching,
    placeholderData: keepPreviousData,
    addFilterHandler,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    moveToPaginationPage,
    totalPagesCount: paginatedData?.totalPagesCount,
    totalRows: paginatedData?.totalRows,
    listType,
  };
};
