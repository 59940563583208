import { PropsWithChildren, useCallback } from "react";
import { useOnboarding } from "./useOnboarding";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import "./Onboarding.css";
import { useMe } from "@/hooks/useMe.tsx";
export const OnboardingWrapper = ({ children }: PropsWithChildren) => {
  const {
    steps,
    isReady,
    currentOnboardingStep,
    setIsReady,
    nextOnboardingStep,
    onboardingCanShow,
    startOnboarding,
  } = useOnboarding();

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    console.log("handleJoyrideCallback", data);
    const { action, index, origin, status, type, step, lifecycle } = data;
    if (action === ACTIONS.NEXT && lifecycle === "complete") {
      nextOnboardingStep();
      return;
    }
    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // nextOnboardingStep();
      console.log("close");
      startOnboarding(false);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      console.log("TARGET_NOT_FOUND");
      setIsReady(false);
      // Update state to advance the tour
      // setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsReady(false);
    }
  }, []);

  const currentStepClickable = steps[currentOnboardingStep]?.clickable ?? true;
  return (
    <>
      {onboardingCanShow && (
        <Joyride
          hideCloseButton
          hideBackButton
          disableScrolling
          disableOverlayClose
          continuous
          steps={steps}
          run={isReady}
          spotlightClicks={currentStepClickable}
          stepIndex={currentOnboardingStep}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              backgroundColor: "#f9f9f9",
              // overlayColor: "#5882bd",
              primaryColor: "#d9376e",
              width: 300,
              zIndex: 1000,
            },
          }}
        />
      )}

      {children}
    </>
  );
};
