import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";

export const useMe = ({ setFilters }: { setFilters?: any }) => {
  const { data: me, refetch } = useQuery({
    queryKey: ["me"],
    // staleTime: 20000,
    queryFn: async ({ signal }) => {
      try {
        const url = `/me`;
        const resp = await AxiosInstance.get<{
          email: string;
          watch_list: string[];
          black_list: string[];
          filter_list: any[];
          saved_searches: any[];
          subscription: boolean;
          trial_used?: boolean;
        }>(url, {
          signal,
        });
        setFilters && setFilters(resp?.data?.filter_list || []);
        return resp.data;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
  });

  return {
    me,
    refetchMe: refetch,
  };
};
