import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog.tsx";
import React, { useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { useMe } from "@/hooks/useMe.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { business, experience, roles, sources } from "@/consts/formData.ts";
import { DialogTitle } from "@radix-ui/react-dialog";
import { CheckIcon } from "@radix-ui/react-icons";

type activeFieldType = null | number;

interface SurveyModalProps {
  formPage: number;
  setFormPage: (page: number) => void;
  setFormPageIsOpen: (isOpen: boolean) => void;
}

const SurveyModal = ({
  formPage,
  setFormPage,
  setFormPageIsOpen,
}: SurveyModalProps) => {
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    business: "",
    experience: "",
    sources: "",
  });
  const [fieldIsActive, setFieldIsActive] = useState<activeFieldType>(null);
  const [inputValue, setInputValue] = useState({
    role: "Other",
    business: "Other",
    experience: "Other",
    sources: "Other",
  });
  const { me } = useMe({});

  const nextButtonDisabled = () => {
    switch (formPage) {
      case 0:
        return formData.name.length <= 2;
      case 1:
        return !formData.role;
      case 2:
        return !formData.business;
      case 3:
        return !formData.experience;
      case 4:
        return !formData.sources;
      case 5:
        return false;
    }
  };

  const handleNextButton = () => {
    if (formPage !== 5) {
      setFieldIsActive(null);
      setFormPage(formPage + 1);
    } else {
      setFormPageIsOpen(false);
    }
  };

  console.log("formData:", formData);

  return (
    <DialogContent showCloseButton={false} className="p-8 transition gap-8">
      <DialogTitle className="hidden"></DialogTitle>
      <div className="flex h-fit relative transition duration-700 flex-row items-start w-full">
        {[2, 3, 4, 5].map((step) => (
          <React.Fragment key={step}>
            <div className={`flex flex-col ${step === 5 ? "w-fit" : "w-full"}`}>
              <div className="ml-2 flex w-full items-center flex-row">
                <div
                  className={`flex border-2 items-center justify-center transition duration-700 rounded-full h-6 w-6 min-h-6 min-w-6 ${
                    formPage - 1 >= step - 2
                      ? "border-accent-1"
                      : "border-gray-300"
                  }`}
                >
                  {formPage >= step && (
                    <div className="bg-accent-1 flex items-center justify-center transition duration-700 min-h-4 min-w-4 w-4 h-4 rounded-full">
                      <CheckIcon className="h-4 w-4 text-white" />
                    </div>
                  )}
                </div>
                {step < 5 && (
                  <span
                    className={`w-full border-t-2 rounded-full transition duration-700 ${
                      formPage > step
                        ? "border-solid border-accent-1"
                        : formPage > step - 1
                          ? "font-medium border-accent-1"
                          : "font-regular border-gray-300"
                    }`}
                  />
                )}
              </div>
              <div
                className={`text-sm text-nowrap transition mt-2 ${
                  formPage >= step - 1
                    ? "font-medium text-accent-1"
                    : "font-regular text-gray-300"
                }`}
              >
                {step === 5 ? "Finish" : `Step ${step - 1}`}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

      {/* ⬇ Keeps the console clear as <Dialog> must have non-conditional <DialogDescription> as child */}
      <DialogDescription className="hidden" />

      {formPage === 0 && (
        <>
          <DialogHeader className="font-bold text-xl">
            Hello, {me?.email && me.email.split("@")[0]}!
          </DialogHeader>
          <DialogDescription className="text-md text-gray-600">
            Before you get started, we have a few questions to help us improve
            your future experience!
          </DialogDescription>
          <form>
            <div className="flex flex-col gap-2">
              <Label className="text-gray-400">
                Please enter your name to continue
              </Label>
              <Input
                type="text"
                name="name"
                placeholder="Your name"
                value={formData.name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setFormData((prev) => ({ ...prev, [name]: value }));
                }}
              />
            </div>
          </form>
        </>
      )}
      {formPage === 1 && (
        <>
          <DialogHeader className="flex flex-col gap-0">
            <div className="font-bold text-xl">What is your role?</div>
            <div className="font-medium !mt-0 text-md text-gray-600">
              Please tell us more about yourself:
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-2">
              {roles.map((s, index) =>
                index === roles.length - 1 ? (
                  <Input
                    placeholder={s.role}
                    className={`h-16 p-6 text-lg bg-gray-100 font-medium ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 text-black"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    value={inputValue.role}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputValue((prev) => ({ ...prev, role: value }));
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        role: value,
                      }));
                    }}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        role: inputValue.role,
                      }));
                    }}
                  />
                ) : (
                  <div
                    className={`flex flex-col w-full bg-gray-100 px-6 h-24 rounded-md-native items-start justify-center gap-1 transition ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 pointer-events-none"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        role: roles[index].role,
                      }));
                    }}
                  >
                    <div
                      className={`${
                        fieldIsActive === index
                          ? "text-black font-medium text-lg"
                          : "text-gray-500 font-medium text-lg"
                      }`}
                    >
                      {s.role}
                      <p className="text-sm">{s.description}</p>
                    </div>
                  </div>
                ),
              )}
            </div>
          </DialogDescription>
        </>
      )}
      {formPage === 2 && (
        <>
          <DialogHeader className="flex flex-col gap-0">
            <div className="font-bold text-xl">What is your business type?</div>
            <div className="font-medium !mt-0 text-md text-gray-600">
              Please, tell us a about your preferred business model:
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-2">
              {business.map((s, index) =>
                index === business.length - 1 ? (
                  <Input
                    placeholder={s}
                    className={`h-16 p-6 text-lg bg-gray-100 font-medium ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 text-black"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    value={inputValue.business}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputValue((prev) => ({ ...prev, business: value }));
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        business: value,
                      }));
                    }}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        business: inputValue.business,
                      }));
                    }}
                  />
                ) : (
                  <div
                    className={`flex flex-col w-full bg-gray-100 px-6 h-16 rounded-md-native items-start justify-center gap-1 transition ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 pointer-events-none"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        business: business[index],
                      }));
                    }}
                  >
                    <div
                      className={`${
                        fieldIsActive === index
                          ? "text-black font-medium text-lg"
                          : "text-gray-500 font-medium text-lg"
                      }`}
                    >
                      {s}
                    </div>
                  </div>
                ),
              )}
            </div>
          </DialogDescription>
        </>
      )}
      {formPage === 3 && (
        <>
          <DialogHeader className="flex flex-col gap-0">
            <div className="font-bold text-xl">What is your experience?</div>
            <div className="font-medium !mt-0 text-md text-gray-600">
              Please, tell us a about your experience:
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-2">
              {experience.map((s, index) =>
                index === experience.length - 1 ? (
                  <Input
                    placeholder={s}
                    className={`h-16 p-6 text-lg bg-gray-100 font-medium ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 text-black"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    value={inputValue.experience}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputValue((prev) => ({ ...prev, experience: value }));
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        experience: value,
                      }));
                    }}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        experience: inputValue.experience,
                      }));
                    }}
                  />
                ) : (
                  <div
                    className={`flex flex-col w-full bg-gray-100 px-6 h-16 rounded-md-native items-start justify-center gap-1 transition ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 pointer-events-none"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        experience: experience[index],
                      }));
                    }}
                  >
                    <div
                      className={`${
                        fieldIsActive === index
                          ? "text-black font-medium text-lg"
                          : "text-gray-500 font-medium text-lg"
                      }`}
                    >
                      {s}
                    </div>
                  </div>
                ),
              )}
            </div>
          </DialogDescription>
        </>
      )}
      {formPage === 4 && (
        <>
          <DialogHeader className="flex flex-col gap-0">
            <div className="font-bold text-xl">
              How did you learn about Next Big Niche?
            </div>
            <div className="font-medium !mt-0 text-md text-gray-600">
              Sources with which you learned about Next Big Niche:
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-2">
              {sources.map((s, index) =>
                index === sources.length - 1 ? (
                  <Input
                    placeholder={s}
                    className={`h-16 p-6 text-lg bg-gray-100 font-medium ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 text-black"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    value={inputValue.sources}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputValue((prev) => ({ ...prev, sources: value }));
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        sources: value,
                      }));
                    }}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        sources: inputValue.sources,
                      }));
                    }}
                  />
                ) : (
                  <div
                    className={`flex flex-col w-full bg-gray-100 px-6 h-16 rounded-md-native items-start justify-center gap-1 transition ${
                      fieldIsActive === index
                        ? "bg-white border border-accent-1 pointer-events-none"
                        : "hover:bg-white border hover:border-gray-300 cursor-pointer"
                    }`}
                    onClick={() => {
                      setFieldIsActive(index);
                      setFormData((prev) => ({
                        ...prev,
                        sources: sources[index],
                      }));
                    }}
                  >
                    <div
                      className={`${
                        fieldIsActive === index
                          ? "text-black font-medium text-lg"
                          : "text-gray-500 font-medium text-lg"
                      }`}
                    >
                      {s}
                    </div>
                  </div>
                ),
              )}
            </div>
          </DialogDescription>
        </>
      )}
      {formPage === 5 && (
        <>
          <DialogHeader className="flex flex-col items-center justify-center gap-0 min-h-32">
            <div className="font-bold text-xl">Thank you for sharing!</div>
            <div className="font-medium !mt-0 text-md text-gray-600">
              You can start using a service now.
            </div>
          </DialogHeader>
        </>
      )}
      <DialogFooter>
        {formPage >= 1 && (
          <Button
            variant="outline"
            className="min-w-[96px]"
            onClick={() => setFormPage(formPage - 1)}
          >
            {formPage === 5 ? "Back" : "Previous"}
          </Button>
        )}
        <Button
          className="min-w-[96px]"
          onClick={handleNextButton}
          disabled={nextButtonDisabled()}
        >
          {formPage === 5 ? "Finish" : "Next"}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default SurveyModal;
