import { Button } from "@/components/ui/button.tsx";
import { FC, useState } from "react";
import { Drawer } from "vaul";

import { DialogClose } from "@/components/ui/dialog.tsx";

import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";
import { MinMaxInput } from "@/components/filters/MinMaxInput/MinMaxInput.tsx";
import { QueryFilter } from "@/components/filters/QueryFilter/QueryFilter.tsx";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { SwitchFilter } from "@/components/filters/SwtichFilter/SwitchFilter.tsx";
import { CategoryFilter } from "@/components/filters/CategoryFilter/CategoryFilter.tsx";

import { SaveSearchDialog } from "@/components/dialogs/SaveSearchDialog.tsx";
import { FilterIcon } from "lucide-react";
import Lottie from "lottie-react";
import loadingAnimation from "@/animations/loading.json";
import { ONBOARDING_CLASSES } from "@/components/onboarding/useOnboarding.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";

type TrendFiltersDialogProps = {
  addFilters: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  onSearch: () => void;
  savedSearchFilters: any[];
  disabled: boolean;
  isLoading?: boolean;
};

export const TableFilterDialog: FC<TrendFiltersDialogProps> = ({
  addFilters,
  onSearch,
  savedSearchFilters,
  disabled,
  isLoading = false,
}) => {
  const { nextStep, isReady } = useOnboardingStore();
  const [dropFilters, setDropFilters] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState<OptionFilterReturn[]>(
    [],
  );

  const onSearchHandler = () => {
    addFilters({ filters: appliedFilters });
    setTimeout(() => {
      onSearch();
      setDropFilters(false);
    }, 500);
  };

  const addFiltersHandler = ({
    filters,
  }: {
    filters: OptionFilterReturn[];
  }) => {
    setAppliedFilters((prevFilters) => {
      const filteredPrevFilters = prevFilters.filter(
        (filter) =>
          !filters.some((newFilter) => newFilter.queryKey === filter.queryKey),
      );

      const validNewFilters = filters.filter(
        (filter) =>
          filter.filterValue !== null &&
          filter.filterValue !== "" &&
          filter.filterValue !== false,
      );

      const newF = [...filteredPrevFilters, ...validNewFilters];
      // debouncedSaveFiltersToLocalStorage(newF);
      return newF;
    });
    setDropFilters(false);
  };
  return (
    <Drawer.Root direction="right" className="bg-white">
      <Drawer.Trigger asChild disabled={disabled}>
        <Button
          disabled={disabled || isLoading}
          variant="outline"
          size={"sm"}
          className={`${ONBOARDING_CLASSES.step11}`}
          onClick={() => {
            isReady && nextStep();
          }}
        >
          {!isLoading ? (
            <>
              <FilterIcon className={"mr-[6px] w-4 h-4"} strokeWidth={1} />
              Filters
            </>
          ) : (
            <div className={"flex justify-center max-w-[61px] "}>
              <Lottie
                size={50}
                width={50}
                height={50}
                frameBorder={100}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>
          )}
        </Button>
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/70 z-[30]" />
        <Drawer.Content className="py-6 pl-6 bg-white flex flex-col rounded-t-[10px] rounded-b-[10px] h-full w-1/2 fixed bottom-0 right-0 z-30 ">
          <ScrollArea>
            <div className={"flex flex-col gap-6 mr-4"}>
              <div className={"flex w-full gap-4 flex-col"}>
                <div>Search Term</div>
                <FilterContainer>
                  <CategoryFilter
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"category_list"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>

                {/*<FilterContainer>*/}
                {/*  <SwitchFilter*/}
                {/*    selectedSavedSearch={savedSearchFilters}*/}
                {/*    dropFilter={dropFilters}*/}
                {/*    reversed*/}
                {/*    queryKey={"adult_product"}*/}
                {/*    onSelect={addFiltersHandler}*/}
                {/*  />*/}
                {/*</FilterContainer>*/}
                <FilterContainer>
                  <SwitchFilter
                    selectedSavedSearch={savedSearchFilters}
                    dropFilter={dropFilters}
                    queryKey={"new_key"}
                    popoverContent={
                      "New search terms appeared less than 1 year ago"
                    }
                    onSelect={addFiltersHandler}
                  />
                </FilterContainer>
              </div>

              <div className={"w-full  flex "}>
                <QueryFilter
                  selectedSavedSearch={savedSearchFilters}
                  queryKey={"search_term"}
                  dropFilter={dropFilters}
                  onSearchQuery={addFiltersHandler}
                />
              </div>
              <div className="pt-4">Popularity analysis</div>
              <div className={"flex w-full gap-[40px]"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"avg_ly_sv"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"last_week_rank"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>
              </div>

              <div className={"flex  flex-col gap-[32px] "}>
                <div className={"flex w-full flex-wrap gap-10 "}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_12_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_12_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                </div>
                <div className={"flex w-full gap-10"}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_6_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_6_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                </div>
                <div className={"flex w-full gap-10"}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_3_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_3_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                    />
                  </FilterContainer>
                </div>
              </div>

              <div className="pt-4">First page data</div>
              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"reviews_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  >
                    Reviews: average
                  </MinMaxInput>
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"reviews_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  >
                    Reviews: median
                  </MinMaxInput>
                </FilterContainer>
              </div>

              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"price_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  />
                </FilterContainer>

                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"price_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  />
                </FilterContainer>
              </div>

              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"rating_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  />
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"rating_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                  />
                </FilterContainer>
              </div>
              {/*TODO: rollback it when fixed, COLUMNS SETTINGS CHECK TOO*/}
              {/*<div className={"flex w-full gap-10"}>*/}
              {/*  <FilterContainer>*/}
              {/*    <MinMaxInput*/}
              {/*      selectedSavedSearch={savedSearchFilters}*/}
              {/*      queryKey={"global_search_count"}*/}
              {/*      onSave={addFiltersHandler}*/}
              {/*      dropFilter={dropFilters}*/}
              {/*    />*/}
              {/*  </FilterContainer>*/}
              {/*</div>*/}
              <div className="pt-4">Top-3 products</div>
              <div className={"flex w-full gap-10 mb-[36px]"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"click_share_sum"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"convershion_share_sum"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>
              </div>
            </div>
          </ScrollArea>

          <div className={"flex w-full justify-between pt-6"}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setDropFilters(true);
                // setSavedSearchFilters([]);
              }}
            >
              Clear all
            </Button>

            <div className={"flex gap-[16px] pr-6"}>
              <SaveSearchDialog appliedFilters={appliedFilters} />

              <DialogClose asChild>
                <Button
                  className={"w-[150px]"}
                  size={"sm"}
                  onClick={onSearchHandler}
                  variant="accent"
                >
                  Show Results
                </Button>
              </DialogClose>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
