import { FilteredColumns, PopupColumns } from "@/hooks/usePaginatedTrends.tsx";

type ColumnOptions = {
  shownName: string;
  sortable?: boolean;
  returnMinus?: string;
  isImg?: boolean;
  isLink?: boolean;
  isChart?: boolean;
  hasProductLink?: boolean;
  isNumberValueWithKCut?: boolean;
  yesNoValue?: boolean;
  isSearchTerm?: boolean;
  isPercent?: boolean;
  isMinMaxFilter?: boolean;
  isPopup?: boolean;
  hidden?: boolean;
  showDollar?: boolean;

  filterName?: string;
  width?: number;
  tootipText?: string;
  isId?: boolean;
};

const FiltersNames = {
  sv_increase_12_mnth_abs: {
    shownName: "Search Volume Change Year over Year",
    hidden: true,
  },
  sv_increase_6_mnth_abs: {
    shownName: "Search Volume Change past 6 months",
    hidden: true,
  },
  sv_increase_3_mnth_abs: {
    shownName: "Search Volume Change past 3 months",
    hidden: true,
  },
  sv_increase_12_mnth_ratio: {
    shownName: "Search Volume Change Year over Year",
    isPercent: true,
    hidden: true,
  },
  sv_increase_6_mnth_ratio: {
    shownName: "Search Volume Change past 6 months",
    isPercent: true,
    hidden: true,
  },
  sv_increase_3_mnth_ratio: {
    shownName: "Search Volume Change past 3 months",
    isPercent: true,
    hidden: true,
  },
};

export const POPUP_COLUMNS: Record<PopupColumns, ColumnOptions> = {
  asin_1: {
    shownName: "asin 1",
    hasProductLink: true,
    isLink: true,
    isPopup: true,
  },
  asin_2: {
    shownName: "asin 2",
    hasProductLink: true,
    isLink: true,
    isPopup: true,
  },
  asin_3: {
    shownName: "asin 3",
    hasProductLink: true,
    isLink: true,
    isPopup: true,
  },
  click_share_1: {
    shownName: "click share 1",
    isPercent: true,
    isPopup: true,
  },
  brand_1: {
    shownName: "brand 1",
    isPopup: true,
  },
  brand_2: {
    shownName: "brand 2",
    isPopup: true,
  },
  brand_3: {
    shownName: "brand 3",
    isPopup: true,
  },
  click_share_2: {
    shownName: "click share 2",
    isPercent: true,
    isPopup: true,
  },
  click_share_3: {
    shownName: "click share 3",
    isPercent: true,
    isPopup: true,
  },
  conv_share_1: {
    shownName: "conv share 1",
    isPercent: true,
    isPopup: true,
  },
  conv_share_2: {
    shownName: "conv share 2",
    isPercent: true,
    isPopup: true,
  },
  conv_share_3: {
    shownName: "conv share 3",
    isPercent: true,
    isPopup: true,
  },
  img_link_1: {
    shownName: "img link 1",
    isImg: true,
    isPopup: true,
  },
  img_link_2: {
    shownName: "img link 2",
    isImg: true,
    isPopup: true,
  },
  img_link_3: {
    shownName: "img link 3",
    isImg: true,
    isPopup: true,
  },
};

//TODO:  should has hidden
export const HIDDEN_COLS = {
  sv_change_12m: {
    shownName: "Search Volume Change Year over Year",
    sortable: true,
    hidden: true,
  },
  sv_change_6m: {
    shownName: "Search Volume Change past 6 months",
    sortable: true,
    hidden: true,
  },
  sv_change_3m: {
    shownName: "Search Volume Change past 3 months",
    sortable: true,
    hidden: true,
  },

  total_click_share: {
    shownName: "Click Share vs Conversion Share",
    sortable: true,
    isPercent: true,
    isMinMaxFilter: true,
    hidden: true,
  },

  adult_product: {
    shownName: "Show adult products",
    hidden: true,
  },
  top_product_category: {
    shownName: "Top-1 Product Category",
    hidden: true,
  },

  search_term_id: {
    hidden: true,
    isId: true,
  },
  ...POPUP_COLUMNS,
};

export const ColumnSettingsByColumnNameInOrder: Record<
  FilteredColumns,
  ColumnOptions
> = {
  root_category: {
    shownName: "Category",
    sortable: false,

    isCategory: true,
  },
  search_term: {
    shownName: "Search Term",
    isSearchTerm: true,
  },
  top_product_img: {
    shownName: "Top Products",
    isImg: true,
    hasProductLink: true,
    sortable: false,
    width: 150,
  },
  new_key: {
    shownName: "New Search Term",
    filterName: "Show only new search terms",
    yesNoValue: true,
  },
  avg_ly_sv: {
    shownName: `Av.\u00A0SV past 12m`,
    sortable: true,
    isNumberValueWithKCut: true,

    filterName: "Average last year search volume:",
    tootipText: "Average Search Volume past 12 months",
  },
  last_week_rank: {
    shownName: "Last Week SV",

    isMinMaxFilter: true,
    isNumberValueWithKCut: true,

    tootipText: "Last Week Search Volume",
  },
  dates: {
    shownName: "Search Volume History",
    isChart: true,
    sortable: false,
    width: 180,
  },
  sv_change: {
    shownName: "Search Volume Change ",
    // sv_change_12m: {
    //   shownName: "Search Volume Change Year over Year",
    //   sortable: true,
    // },
    // sv_change_6m: {
    //   shownName: "Search Volume Change past 6 months",
    //   sortable: true,
    // },
    // sv_change_3m: {
    //   shownName: "Search Volume Change past 3 months",
    //   sortable: true,
    // },
  },

  reviews_mean: {
    shownName: "Reviews: average",
    sortable: true,

    isMinMaxFilter: true,
    isNumberValueWithKCut: true,
  },
  reviews_median: {
    shownName: "Reviews: median",
    sortable: true,

    isMinMaxFilter: true,
    isNumberValueWithKCut: true,
  },
  price_mean: {
    shownName: "Price: average",
    sortable: true,

    isMinMaxFilter: true,
    isNumberValueWithKCut: true,
    showDollar: true,
  },
  price_median: {
    shownName: "Price: median",
    sortable: true,

    isNumberValueWithKCut: true,
    isMinMaxFilter: true,
    showDollar: true,
  },
  rating_median: {
    shownName: "Ratings: median",
    sortable: true,

    isMinMaxFilter: true,
  },
  rating_mean: {
    shownName: "Ratings: average",
    sortable: true,

    isMinMaxFilter: true,
  },
  global_search_count: {
    shownName: "Competing products",
    sortable: true,

    isMinMaxFilter: true,

    isNumberValueWithKCut: true,

    filterName: "Competing products",
    tootipText: "Total # of products found on Amazon by search term",

    //TODO: need fix on BE side and rollback it
    hidden: true,
  },

  click_share_sum: {
    shownName: "Top-3 P. Click Share",
    sortable: true,
    isPercent: true,
    isMinMaxFilter: true,
  },
  convershion_share_sum: {
    shownName: "Top-3 P. Conversion Share",
    sortable: true,
    isPercent: true,
    isMinMaxFilter: true,
  },

  ...HIDDEN_COLS,

  ...FiltersNames,
};
