import {
  Controller,
  Path,
  RegisterOptions,
  UseFormReturn,
} from "react-hook-form";

import { Input } from "@/components/ui/input";
import { ReactNode } from "react";

interface Props<T extends object> {
  type?: "text" | "email" | "password" | "url";
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: string;
  bottomMargin?: string;
  multiline?: boolean;
  className?: string;
  showLabel?: boolean;
  label?: ReactNode;
  form: UseFormReturn<T>;
  fieldName: Path<T>;
  validation?: RegisterOptions;
  errorClassName?: string;
  required?: boolean;
  onEnterPressed?: () => void;
  icon?: string;
  labelIcon?: ReactNode;
}

export const FormInput = <T extends HTMLInputElement>({
  className,
  errorClassName,
  placeholder,
  disabled = false,
  fieldName,
  form,
  validation,
  autoComplete,
  onEnterPressed = () => {},
  icon,
  type = "text",
}: Props<T>) => {
  return (
    <Controller
      name={fieldName}
      control={form.control}
      rules={validation}
      render={({ field, fieldState }) => {
        const validationError = fieldState.error?.message;
        return (
          <div className={`${icon ? "flex" : "inline"}`}>
            <Input
              {...field}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              className={
                `placeholder:text-grey-darkPlaceholder h-10 w-full rounded-md-native outline-0 ${
                  disabled ? "bg-grey-disabled text-grey-dark" : "text-black "
                }` + className
              }
              autoComplete={autoComplete}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onEnterPressed();
                }
              }}
            />
            {validationError && (
              <span
                className={`${errorClassName || ""}  absolute text-red-500 text-xs`}
              >
                {validationError}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};
