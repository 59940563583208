import { Switch } from "@/components/ui/switch.tsx";
import { Label } from "@/components/ui/label.tsx";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { useMe } from "@/hooks/useMe.tsx";

interface SwitchFilterProps extends PropsWithChildren {
  onSelect: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  queryKey: ClientFilters;
  popoverContent?: string;
  reversed?: boolean;
  dropFilter: boolean;
  selectedSavedSearch: any[];
}

export const SwitchFilter: FC<SwitchFilterProps> = ({
  children,
  queryKey,
  onSelect,
  popoverContent,
  reversed,
  dropFilter = false,
  selectedSavedSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const { me } = useMe({});
  const filterList = me?.filter_list || [];

  const handleMouseEnter = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (dropFilter) {
      setIsChecked(false);
      onSelect({ filters: [{ queryKey, filterValue: false }] });
    }
  }, [dropFilter]);

  const handleMouseLeave = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (filterList.length !== 0) {
      const filter = filterList?.find((f) => f.queryKey === queryKey);

      if (filter !== undefined) {
        setIsChecked(Boolean(filter.filterValue));
        onSelect({ filters: [{ queryKey, filterValue: filter.filterValue }] });
      }
    }
  }, []);

  useEffect(() => {
    const appliedFilter = selectedSavedSearch?.find(
      (f) => f?.queryKey === queryKey,
    );

    if (appliedFilter !== undefined) {
      setIsChecked(Boolean(appliedFilter.filterValue));
      onSelect({
        filters: [{ queryKey, filterValue: appliedFilter.filterValue }],
      });
    }
  }, [onSelect, queryKey, selectedSavedSearch]);

  return (
    <div className="flex items-center justify-center gap-1">
      <div className={"flex justify-center gap-2"}>
        <Switch
          checked={isChecked}
          id={queryKey}
          onCheckedChange={(checked) => {
            const reversedValue = checked ? "" : "false";
            const value = checked ? "true" : "";
            setIsChecked(checked);
            onSelect({
              filters: [
                {
                  queryKey,
                  filterValue: `${reversed ? reversedValue : value}`,
                },
              ],
            });
          }}
        />
        <Label
          htmlFor={queryKey}
          className={`${isChecked && "!text-black"} text-sm font-light  text-gray-600 mb-[8px] truncate transition-colors `}
        >
          {ColumnSettingsByColumnNameInOrder?.[queryKey]?.filterName ||
            ColumnSettingsByColumnNameInOrder?.[queryKey]?.shownName ||
            children}
        </Label>
        {popoverContent && (
          <Popover open={open}>
            <PopoverTrigger
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InfoCircledIcon className={"text-sm mb-[8px] ml-[0px]"} />
            </PopoverTrigger>
            <PopoverContent
              side={"top"}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="text-sm"
            >
              {popoverContent}
            </PopoverContent>
          </Popover>
        )}
      </div>
    </div>
  );
};
