import { useOnboardingStore } from "./useOboardingStore";
import { useEffect } from "react";
import { Step } from "react-joyride";

export const ONBOARDING_CLASSES = {
  "onboarding-trend-step-1": "onboarding-trend-step-1",
  step2: "onboarding-search-term-step-2",
  step3: "onboarding-top-products-step-3",
  step4: "onboarding-search-history-step-4",
  step5: "onboarding-search-history-step-5-click",
  step6: "onboarding-search-history-step-6-seasons",
  step7: "onboarding-step-7-search-volume-columns",
  step8: "onboarding-step-8-search-volume-columns-sort",
  step9: "onboarding-step-9-trend-selection",
  step10: "onboarding-step-9-highlight-add-to-watch-list-button",
  step11: "onboarding-step-9-highlight-filters",
};

export const useOnboarding = () => {
  const {
    currentOnboardingStep,
    isReady,
    nextStep,
    setIsReady,
    setStop,
    stop,
    onboardingCanShow,
    startOnboarding,
  } = useOnboardingStore();

  const steps: Step[] = [
    {
      target: `.${ONBOARDING_CLASSES["onboarding-trend-step-1"]}`,
      content: "This is a trend",
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.step2}`,
      content:
        "This is a search term, you can click on to open it on amazon marketplace ",
      disableBeacon: true,
      placement: "bottom-start",
    },
    {
      target: `.${ONBOARDING_CLASSES.step3}`,
      content:
        "This is a most clicked product for this search term, you can hover over it (click on it open amazon marketplace)",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step4}`,
      content: "This is search volume history, click on it to expand",
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step5}`,
      content: "",
      disableBeacon: true,
      hideFooter: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step5}`,
      content: "Here is the search volume chart, it is divided by seasons",
      disableBeacon: true,
    },

    {
      target: `.${ONBOARDING_CLASSES.step6}`,
      content: "You can check seasons colors out here",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step7}`,
      content:
        "Here is search volume change column, it contains both percentage and integer values, you can sort the trends out by each value. ",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step8}`,
      content:
        "You can sort by 12, 6, 3 months in percent and integer values. Just click on it and select the type of sorting you want.",
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.step9}`,
      content:
        "You can select favorite trend and add it to watch list. Let's try add trend to watch list",
      disableBeacon: true,
      clickable: true,
      hideFooter: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step10}`,
      content: "And add it to watch list",
      disableBeacon: true,
      clickable: true,
      hideFooter: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step10}`,
      content: "And add it to watch list",
      disableBeacon: true,
      clickable: true,
      hideFooter: true,
    },

    {
      target: `.${ONBOARDING_CLASSES.step11}`,
      content:
        "You can also filter trends with some parameters, just click on filters button",
      disableBeacon: true,
      clickable: true,
      hideFooter: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.step11}`,
      content:
        "You can also filter trends with some parameters, just click on filters button",
      disableBeacon: true,
      clickable: true,
      hideFooter: true,
    },
  ];

  useEffect(() => {
    if (stop) {
      setIsReady(false);
      return;
    }
    const t = setTimeout(() => {
      setIsReady(true);
      clearTimeout(t);
    }, 300);
    return () => clearTimeout(t);
  }, [isReady, setIsReady, stop]);

  return {
    currentOnboardingStep,
    isReady,
    steps,
    setIsReady,
    nextOnboardingStep: nextStep,
    onboardingCanShow,
    stopOnboarding: () => setStop(true),
    readyForShowOnboarding: () => setIsReady(true),
    startOnboarding,
    isOnboarding: true,
  };
};
